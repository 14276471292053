<template>
  <div
    @click="handleCardClick"
    class="border border-gray-200 p-6 rounded-lg shadow-sm cursor-pointer hover:bg-gray-50 hover:shadow-md hider-scrollbar"
  >
    <div
      class="relative w-full flex items-start justify-between hide-scrollbar"
    >
      <img
        @click.stop="showImage"
        v-if="request"
        class="w-14 h-10 hover:shadow-sm rounded-full mr-2"
        :src="`https://tcc.mekiotechdigital.com/api/tcc/uploads/${request.tpImage}`"
        alt="Tax Payer"
        srcset=""
      />
      <h2
        :class="`flex-col has-tooltip ${
          isGroup ? 'w-48 text-lg ' : 'w-full h-14 pt-1 text-md overflow-scroll'
        }   text-gray-600 font-medium title-font leading-tight `"
      >
        <!-- <span v-if="!isGroup" class="tooltip bottom-8">
     {{ title }}
    </span> -->
        {{
          title == "Paye Government"
            ? "PAYE Government"
            : title == "Payee Company"
            ? "PAYE Company"
            : title
        }}
      </h2>
      <button
        v-if="isGroup"
        @click="showAll"
        class="focus:outline-none underline bg-gray-200 rounded-full px-1.5 text-gray-500"
      >
        <i
          class="fas fa-external-link-square-alt hover:text-gray-700 text-xl"
        ></i>
      </button>
      <button
        v-else
        @click="showBreakdown"
        class="focus:outline-none underline bg-gray-200 rounded-full px-1.5 text-gray-500 text-lg"
      >
        <i class="fas fa-external-link-square-alt hover:text-gray-700"></i>
      </button>
    </div>
    <p class="leading-relaxed text-gray-700 font-bold text-lg mt-2">
      Total : {{ currencyFormat(total) }}
    </p>
    <p v-if="isGroup" class="leading-relaxed text-sm font-medium text-gray-600">
      Year : {{ year }}
    </p>
    <button
      v-if="isGroup"
      @click="showAll"
      class="px-4 py-1 hidden mt-6 text-xs w-full items-center justify-center rounded-full bg-green-700 text-white shadow-md focus:outline-none ring-2 ring-green-600 font-semibold"
    >
      View details
    </button>
    <div v-if="unit == 'chairman'" class="w-full text-right">
      <div class="flex flex-row items-center space-x-4" v-if="isGroup">
        <button
          :disabled="authorizing"
          @click.prevent.stop="authorizeAll"
          class="px-4 py-1 mt-6 text-xs w-full inline-flex items-center justify-center rounded-full bg-green-primary-dark text-white shadow-md focus:outline-none ring-2 ring-green-600 font-semibold"
        >
          <div v-if="!authorizing">Authorize all</div>
          <div v-else class="flex items-center justify-center">
            <i class="hidden fas fa-circle-notch animate-spin"></i>
            <span>Please wait...</span>
          </div>
        </button>
        <button
          :disabled="declining"
          @click.prevent.stop="declineAll"
          class="px-4 py-1 mt-6 text-xs w-full inline-flex items-center justify-center rounded-full bg-red-700 text-white shadow-md focus:outline-none ring-2 ring-red-600 font-semibold"
        >
          <div v-if="!declining">Decline all</div>
          <div v-else class="flex items-center justify-center">
            <i class="hidden fas fa-circle-notch animate-spin"></i>
            <span class="">Please wait...</span>
          </div>
        </button>
      </div>
      <div v-if="!isGroup" class="flex flex-row items-center space-x-4">
        <button
          :disabled="authorizing"
          @click.prevent.stop="authorize"
          class="px-4 py-1 mt-6 text-xs w-full inline-flex items-center justify-center rounded-md bg-green-primary-dark text-white shadow-md focus:outline-none ring-2 ring-green-600 font-semibold"
        >
          <div v-if="!authorizing">Authorize</div>
          <div v-else class="flex items-center justify-center">
            <i class="hidden fas fa-circle-notch animate-spin"></i>
            <span>Please wait...</span>
          </div>
        </button>
        <button
          :disabled="declining"
          @click.prevent.stop="decline"
          class="px-4 py-1 mt-6 text-xs w-full inline-flex items-center justify-center rounded-md bg-red-700 text-white shadow-md focus:outline-none ring-2 ring-red-600 font-semibold"
        >
          <div v-if="!declining">Decline</div>
          <div v-else class="flex items-center justify-center">
            <i class="hidden fas fa-circle-notch animate-spin"></i>
            <span>Please wait...</span>
          </div>
        </button>
      </div>
    </div>
    <div v-if="unit == 'collections'" class="w-full text-right">
      <div class="flex flex-row items-center space-x-4" v-if="isGroup">
        <button
          :disabled="authorizing"
          @click.prevent.stop="approveAll"
          class="px-4 py-1 mt-6 text-xs w-full inline-flex items-center justify-center rounded-full bg-green-primary-dark text-white shadow-md focus:outline-none ring-2 ring-green-600 font-semibold"
        >
          <div v-if="!authorizing">Approve all</div>
          <div v-else class="flex items-center justify-center">
            <i class="hidden fas fa-circle-notch animate-spin"></i>
            <span>Please wait...</span>
          </div>
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import emitter from "tiny-emitter/instance";
import { globalMixin } from "@/mixins/global.js";
import Swal from "sweetalert2";
export default {
  props: {
    id: { type: Number },
    ids: { type: Array, required: false },
    request: { type: Object },
    title: { type: String, required: true },
    total: { type: Number, required: true },
    year: { type: Number, required: true },
    phone: { type: String },
    isGroup: { type: Boolean, required: true },
  },
  mixins: [globalMixin],
  computed: {
    unit() {
      //    console.log(this.$store.getters.getUnit.toLowerCase());
      return this.$store.getters.getUnit.toLowerCase();
    },
  },
  data: () => ({
    authorizing: false,
    declining: false,
  }),
  methods: {
    handleCardClick() {
      if (this.isGroup) {
        this.showAll();
      } else {
        this.showBreakdown();
      }
    },
    authorize() {
      this.authorizing = true;
      this.$store
        .dispatch("authorizeById", {
          id: this.id,
          phone: this.phone,
          status: "authorize",
          year: this.year,
        })
        .then(() => {
          this.$store.dispatch("reduceRequestSingles", this.id);
          this.authorizing = false;
        })
        .catch((err) => {
          this.handleError(err);
        })
        .finally(() => {
          this.authorizing = false;
        });
    },
    decline() {
      Swal.fire({
        title: "Are you sure?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, decline it!",
      }).then((result) => {
        if (result.isConfirmed) {
          this.declining = true;
          this.$store
            .dispatch("authorizeById", {
              id: this.id,
              phone: this.phone,
              status: "decline",
              year: this.year,
            })
            .then(() => {
              this.$store.dispatch("reduceRequestSingles", this.id);
              this.declining = false;
            })
            .catch((err) => {
              this.handleError(err);
            })
            .finally(() => {
              this.declining = false;
            });
          // Swal.fire("Poof! Your imaginary file has been deleted!", {
          //   icon: "success",
          // });
        }
      });
    },
    approve() {
      this.authorizing = true;
      Swal.fire({
        title: "RevenueHouse",
        text: "Approved!" + this.id,
        icon: "success",
      });
      this.authorizing = false;
    },
    approveAll() {
      this.authorizing = true;
      Swal.fire({
        title: "RevenueHouse",
        text: "Approval done!",
        icon: "success",
      });
      this.authorizing = false;
    },
    authorizeAll() {
      this.authorizing = true;
      this.$store
        .dispatch("authorizeAll", {
          orgName: this.title,
          year: this.year,
          status: "authorize",
        })
        .then(() => {
          this.$store.dispatch("getRequestYears");
        })
        .then(() => {
          this.$store.dispatch("reduceRequests", {
            title: this.title,
            year: this.year,
          });
          this.authorizing = false;
        })
        .catch((err) => {
          this.handleError(err);
        })
        .finally(() => {
          this.authorizing = false;
        });
    },
    declineAll() {
      Swal.fire({
        title: "Are you sure?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, decline it!",
      }).then((result) => {
        if (result.isConfirmed) {
          this.declining = true;
          this.$store
            .dispatch("authorizeAll", {
              orgName: this.title,
              year: this.year,
              status: "decline",
            })
            .then(() => {
              this.$store.dispatch("getRequestYears");
            })
            .then(() => {
              this.$store.dispatch("reduceRequests", {
                title: this.title,
                year: this.year,
              });
              this.declining = false;
            })
            .catch((err) => {
              this.handleError(err);
            })
            .finally(() => {
              this.declining = false;
            });
          // Swal.fire("Poof! Your imaginary file has been deleted!", {
          //   icon: "success",
          // });
        }
      });
    },
    showBreakdown() {
      emitter.emit("showBreakdown", this.request);
    },
    showImage() {
      emitter.emit("showImage", this.request);
    },
    showAll() {
      emitter.emit("showAll", { org: this.title, year: this.year });
    },
  },
};
</script>
<style></style>
